import React, { Component } from "react";
import { connect } from "react-redux";
import {
  allUniqueSuppliersFromVarieties,
  fetchEmailDrawerPeople,
  getVarietyPlotNumber,
  filterPlotBedsByTrial
} from "../../../helpers";
import { fetchPeopleAssociatedWith } from "../../../actions/people";
import {
  submitReport,
  emailEvaluationReport,
  checkReportStatus,
  updateCheckReportStatus,
  submitEvaluationExcelReport
} from "../../../actions/reports";
import { updateTrial } from '../../../actions/trials';
import { ReportProcessing, TrialReportHeader } from "../../../ui";
import { EvaluationReportColumns } from "./views";
import { EmailDrawer } from "../../index";
import { SupplierReportSelect, TrialInfoHeader } from "../index";
import { Divider, Grid } from "@material-ui/core";

class EvaluationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailDrawer: false,
      supplier: "",
      showProcessingReport: false
    };
  }
  combineTrialAndFieldVarieties = trialVarietiesArr => {
    trialVarietiesArr = this.filterEmptyObjVarieties(trialVarietiesArr);
    const { fieldVarieties } = this.props.fieldVarieties;
    let fieldVarietiesArr = Object.values(fieldVarieties);
    fieldVarietiesArr = this.filterEmptyObjVarieties(fieldVarietiesArr);
    let combinedTrialAndFieldVarieties = trialVarietiesArr.concat(
      fieldVarietiesArr
    );
    const { trial } = this.props.trials;
    combinedTrialAndFieldVarieties = combinedTrialAndFieldVarieties.filter(
      record => record.trialId === trial.id
    );
    return combinedTrialAndFieldVarieties;
  };

  filterEmptyObjVarieties = objVarietiesArr =>
    objVarietiesArr.filter(objVariety => {
      if (
        objVariety.evaluations !== undefined &&
        objVariety.evaluations.length > 0
      ) {
        return true;
      }
      if (objVariety.comments !== null) {
        return true;
      }
      if (objVariety.growerComments !== null) {
        return true;
      }
      if (objVariety.score > 0) {
        return true;
      }
      if (objVariety.images.length > 0) {
        return true;
      }
      return false;
    });

  allVarietiesAssociatedWithTrial = trialAndFieldVarieties => {
    const { varieties } = this.props.varieties;
    const uniqVarieties = {};
    trialAndFieldVarieties.forEach(obj => {
      const variety = varieties[obj.variety];
      if (variety) {
        uniqVarieties[variety.id] = variety;
      }
    });
    return Object.values(uniqVarieties);
  };

  fitlerTrialVarietiesBySupplier = () => {
    const { trialVarieties } = this.props.trialVarieties;
    let trialVarietiesArr = Object.values(trialVarieties);

    if (this.state.supplier.length === 0) {
      return trialVarietiesArr;
    }
    const selectedSupplierId = parseInt(this.state.supplier);
    const { varieties } = this.props.varieties;
    return trialVarietiesArr.filter(
      obj =>
        obj.variety &&
        varieties[obj.variety] &&
        varieties[obj.variety].supplier === selectedSupplierId
    );
  };

  sortTrialAndFieldVarieties = (trialAndFieldVarieties, plotNumbers) => {
    if (trialAndFieldVarieties.length === 0) {
      return trialAndFieldVarieties;
    }
    return trialAndFieldVarieties.sort((a, b) => {
      if (a.trialVarieties && b.trialVarieties) {
        if (a.trialVarieties.length > b.trialVarieties.length) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a.trialVarieties) {
        return -1;
      }
      if (b.trialVarieties) {
        return 1;
      }
      if (plotNumbers[a.id] < plotNumbers[b.id]) {
        return -1;
      }
      if (plotNumbers[a.id] > plotNumbers[b.id]) {
        return 1;
      }
      return 0;
    });
  };

  supplierChange = e => {
    this.setState({ ...this.state, supplier: e.target.value });
  };

  sendEmail = supplierId => peopleIds => {
    const { trial } = this.props.trials;
    this.props.emailEvaluationReport(trial.id, supplierId, peopleIds);
    this.toggleEmailDrawer(false);
  };

  sendOnDateUpdateTrial = () => {
    const { trial } = this.props.trials;
    this.props.updateTrial(trial.id, { auto_send_evaluation_report: true })
    this.toggleEmailDrawer(false);
  }

  downloadPDF = supplierId => () => {
    const { trial } = this.props.trials;
    this.props.submitReport("evaluation_report", trial.id, supplierId);
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  downloadExcel = supplierId => () => {
    const { trial } = this.props.trials;
    this.props.submitEvaluationExcelReport(trial.id, supplierId);
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  openEmailDrawerAction = () => {
    const { trial } = this.props.trials;
    fetchEmailDrawerPeople(
      trial,
      this.state.supplier,
      this.props.fetchPeopleAssociatedWith
    );
    this.toggleEmailDrawer(true);
  };

  toggleEmailDrawer = value => {
    this.setState({ ...this.state, showEmailDrawer: value });
  };

  suppliersList = () => {
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    const filteredTrialVarieties = Object.values(trialVarieties).filter(
      tv => tv.trialId === trial.id
    );
    const varietiesFromTrialVarieties = this.allVarietiesAssociatedWithTrial(
      filteredTrialVarieties
    );
    return allUniqueSuppliersFromVarieties(varietiesFromTrialVarieties);
  };

  filterAndSortTrialAndVarieties = plotNumbers => {
    const fitleredTrialVarieties = this.fitlerTrialVarietiesBySupplier();
    const trialAndFieldVarieties = this.combineTrialAndFieldVarieties(
      fitleredTrialVarieties
    );
    return this.sortTrialAndFieldVarieties(trialAndFieldVarieties, plotNumbers);
  };

  _checkReportStatusCall = trial => reportId =>
    this.props.checkReportStatus(trial.id, reportId);

  render() {
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    const { plotBeds } = this.props.plotBeds;
    const filteredPlotBeds = filterPlotBedsByTrial(
      trial,
      trialVarieties,
      plotBeds
    );
    const plotNumbers = getVarietyPlotNumber(
      filteredPlotBeds,
      trial.plotType,
      trial.plotRows,
      trial.plotCols
    );
    const supplierIds = this.suppliersList();
    const trialAndFieldVarieties = this.filterAndSortTrialAndVarieties(
      plotNumbers
    );
    const { fieldVarieties } = this.props.fieldVarieties;
    const filteredFieldVarieties = Object.values(fieldVarieties).filter(
      fv => fv.trialId === trial.id
    );

    const { checkReportStatus } = this.props.reports;
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <TrialReportHeader
            title={"Evaluation Report"}
            trialName={trial.name}
            openEmailDrawerAction={this.openEmailDrawerAction}
            downloadPDF={this.downloadPDF(this.state.supplier)}
            downloadExcel={this.downloadExcel(this.state.supplier)}
            processingReport={checkReportStatus}
          />
          {this.state.showProcessingReport && (
            <ReportProcessing
              checkReportStatusCall={this._checkReportStatusCall(trial)}
            />
          )}
          <SupplierReportSelect
            supplierIds={supplierIds}
            selectedSupplier={this.state.supplier}
            supplierChange={this.supplierChange}
          />
          <TrialInfoHeader
            trial={trial}
            fieldVarieties={filteredFieldVarieties}
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <EvaluationReportColumns
            plotNumbers={plotNumbers}
            showGrowerComments={this.state.supplier.length === 0}
            trialAndFieldVarieties={trialAndFieldVarieties}
          />
        </Grid>
        <EmailDrawer
          showDrawer={this.state.showEmailDrawer}
          toggleDrawer={this.toggleEmailDrawer}
          sendEmail={this.sendEmail(this.state.supplier)}
          sendOnDateUpdateTrial={this.sendOnDateUpdateTrial}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({
  trials,
  fieldVarieties,
  trialVarieties,
  plotBeds,
  reports,
  varieties
}) => ({
  trials,
  fieldVarieties,
  trialVarieties,
  plotBeds,
  reports,
  varieties
});

export default connect(mapStateToProps, {
  updateTrial,
  fetchPeopleAssociatedWith,
  submitReport,
  emailEvaluationReport,
  checkReportStatus,
  updateCheckReportStatus,
  submitEvaluationExcelReport
})(EvaluationReport);
